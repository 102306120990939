import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { TextField, Button, Table, TableBody, TableCell, TableHead, TableRow, Select, MenuItem, InputLabel } from '@material-ui/core';
import TableView from './tableview';
import AdminAPI from '../service/adminapi';
import { aliOverwriteSuffix } from '../constants';
import ALIAPI from '../service/aliapi';
import AlertDialog from './alertdialog';
import TableContainer from '@material-ui/core/TableContainer';
import CustomSnackbar from './customsnackbar';
import CustomDropdown from './customdropdown';

const styles = theme => ({
    formControl: {
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        minWidth: 120,
        display: 'flex',
        alignItems: 'center',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    roundedTextField: {
        '& .MuiOutlinedInput-root': {
            borderRadius: '8px'
        }
    },
    scrollableContainer: {
        maxHeight: 'calc(100vh - 64px - 200px)',
        overflowY: 'auto',
        paddingTop: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1)
    },
    fullWidthTextField: {
        width: '100%',
    },
    formContainer: {
        paddingTop: theme.spacing(2),
        height: '100%',
        overflowY: 'auto',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
    },
    tableHeaderCell: {
        backgroundColor: '#6672b4',
        display: 'flex',
        textTransform: 'uppercase',
        fontWeight: '500'
    },
    tableRowEven: {
        backgroundColor: "#eeeeee",
    },
    submit: {
        textAlign: "right",
    },
    overwrites: {
        marginRight: theme.spacing(2),
    },
    suffix: {
        color: "red"
    },
    fullHeightDialog: {
        '& .MuiDialog-paper': {
            height: '100%',
        },
    },
    container: {
        maxHeight: 590,
    },
    listDropdown: {
        marginBottom: 10
    },
    dialogTableCell: {
        backgroundColor: '#6672b4',
        fontWeight: '500'
    },
    aliNotFound: {
        color: 'red'
    },
    dropdown: {
        width: '300px',
        marginBottom: '10px'
    },
    aliOverwriteColumns: {
        colSpan: 6
    }
});

const aliOverwriteColumns = [
    { label: 'Incorrect Ali', dataKey: 'incorrectAli', sort: false, filter: false },
    { label: 'Correct Ali', dataKey: 'correctAli', sort: false, filter: false },
    { label: '', dataKey: 'suffix', sort: false, filter: false },
];

const searchValidateAliOverwriteColumns = [
    { label: 'Incorrect Ali', dataKey: 'aliFirstColumn', sort: false, filter: false },
    { label: 'Correct Ali', dataKey: 'aliSecondColumn', sort: false, filter: false },
    { label: 'Payloads Affected', dataKey: 'payloadAffected', sort: false, filter: false },
    { label: 'Most Recent', dataKey: 'payload_date', sort: false, filter: false },
]

class AdminAliOverwrite extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            numRows: 25,
            rows: Array.from({ length: 25 }, () => ({
                aliFirstColumn: {
                    value: '',
                    helperText: ''
                },
                aliSecondColumn: {
                    value: '',
                    helperText: ''
                },
                suffix: ''
            })),
            lists: [{
                listName: ''
            }],
            loading: false,
            open: false,
            selectedList: {},
            searchValidateAliOverwriteAlert: {
                open: false,
                title: '',
                text: '',
                buttons: [],
                disableBackdropClick: true,
                disableEscapeKeyDown: true,
                maxWidth: 'xl',
                fullWidth: true,
            },
            updateAliOverwriteAlert: {
                open: false,
                title: '',
                text: '',
                buttons: [],
                disableBackdropClick: true,
                disableEscapeKeyDown: true,
                maxWidth: 'xl',
                fullWidth: true,
            },
            notFoundDuplicateAlert: {
                open: false,
                title: '',
                text: '',
                buttons: [],
                maxWidth: 'xl',
                fullWidth: true,
                scroll: 'paper'
            },
            updateAliOverwriteLoading: false,
            showUpdateAliSuccessAlert: false,
            error: {
                open: false,
                message: ''
            },
            success: {
                open: false,
                message: ''
            },
            validateAliData: [],
            aliOverwriteResultAlert: {
                open: false,
                title: '',
                text: '',
                buttons: [],
                disableBackdropClick: true,
                disableEscapeKeyDown: true,
                maxWidth: 'xl',
                fullWidth: true,
            },
        };
    }

    handleNumRowsChange = (event) => {
        const newNumRows = Number(event.target.value);
        this.setState({
            numRows: newNumRows,
            rows: Array.from({ length: newNumRows }, () => ({
                aliFirstColumn: {
                    value: '',
                    helperText: ''
                },
                aliSecondColumn: {
                    value: '',
                    helperText: ''
                },
                suffix: ''
            })),
        });
    };

    handleInputChange = (index, rowIndex, field, value) => {
        const updatedRows = this.state.rows.map((row, i) =>
            i === rowIndex ? { ...row, [field]: { ...row[field], value } } : row
        );
        this.setState({ rows: updatedRows });
    };

    updateAliOverwriteDialog(response) {
        const { classes } = this.props
        return (
            <TableContainer className={classes.container}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell
                                className={`${classes.dialogTableCell} ${response.aliNotFound.length ? classes.aliNotFound : ''}`}
                            >
                                {`ALIs ${response.aliNotFound.length ? 'Not' : ''} Found`}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {response.aliList.map((row, index) => {
                            return (
                                <TableRow key={index}>
                                    <TableCell key={index}>
                                        {row.aliFirstColumn}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }

    showAliOverwriteResultAlert(title, text, buttons, handler) {
        const me = this;
        const { classes } = this.props;
        const { aliOverwriteResultAlert } = this.state;

        this.setState({
            aliOverwriteResultAlert: {
                ...aliOverwriteResultAlert,
                title,
                text,
                buttons,
                open: true,
                className: `${classes.fullHeightDialog}`,
                handler: (index) => {
                    me.setState({ aliOverwriteResultAlert: { ...aliOverwriteResultAlert, open: false } });
                }
            },
        });
    }

    aliOverwriteResultDialog(aliList) {
        const { classes } = this.props
        const { notFoundAlis } = this.getValidatedData(aliList, this.state.validateAliData);
        return (
            <>
                {
                    !!notFoundAlis.length && (
                        <TableContainer className={classes.container}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            Incorrect ALI
                                        </TableCell>
                                        <TableCell>
                                            Correct ALI
                                        </TableCell>
                                        <TableCell>
                                            Incorrect Found in MariaDB
                                        </TableCell>
                                        <TableCell>
                                            Incorrect Found in MongoDB
                                        </TableCell>
                                        <TableCell>
                                            Correct Found in MariaDB
                                        </TableCell>
                                        <TableCell>
                                            Correct Found in MongoDB
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {notFoundAlis.map((row, index) => {
                                        return (
                                            <TableRow key={index}>
                                                <TableCell key={`${index}-incorrect-ali`}>
                                                    {
                                                        row.incorrectAli
                                                    }
                                                </TableCell>
                                                <TableCell key={`${index}-correct-ali`}>
                                                    {
                                                        row.correctAli
                                                    }
                                                </TableCell>
                                                <TableCell key={`${index}-incorrect-mariadb`}>
                                                    {
                                                        row.aliFirstColumn === aliOverwriteSuffix.NOT_FOUND ? "No" : "Yes"
                                                    }
                                                </TableCell>
                                                <TableCell key={`${index}-incorrect-mongodb`}>
                                                    {
                                                        row.aliFirstColumnInMongo === aliOverwriteSuffix.NOT_FOUND ? "No" : "Yes"
                                                    }
                                                </TableCell>
                                                <TableCell key={`${index}-correct-mariadb`}>
                                                    {
                                                        row.aliSecondColumn === aliOverwriteSuffix.NOT_FOUND ? "No" : "Yes"
                                                    }
                                                </TableCell>
                                                <TableCell key={`${index}-correct-mongodb`}>
                                                    {
                                                        row.aliSecondColumnInMongo === aliOverwriteSuffix.NOT_FOUND ? "No" : "Yes"
                                                    }
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )
                }
            </>
        )
    }

    handleUpdateAliOverwrite(postData) {
        const me = this;
        const { updateAliOverwriteAlert, searchValidateAliOverwriteAlert, notFoundDuplicateAlert } = this.state;
        this.setState({
            updateAliOverwriteLoading: true,
            updateAliOverwriteAlert: {
                ...updateAliOverwriteAlert,
                loading: true,
                loadingText: 'Updating ALIs'
            }
        })
        AdminAPI.updateAliOverwrite(postData).then((response) => {
            this.showAliOverwriteResultAlert(
                "",
                this.aliOverwriteResultDialog(postData.ali_list),
                ['Cancel'],
            );
            this.setState({
                updateAliOverwriteLoading: false,
                updateAliOverwriteAlert: {
                    ...updateAliOverwriteAlert,
                    loading: false,
                    loadingText: '',
                    open: false
                },
                searchValidateAliOverwriteAlert: {
                    ...searchValidateAliOverwriteAlert,
                    loading: false,
                    loadingText: '',
                    open: false
                },
                notFoundDuplicateAlert: {
                    ...notFoundDuplicateAlert,
                    loading: false,
                    loadingText: '',
                    open: false
                },
                success: {
                    open: true,
                    message: 'ALIs updated successfully',
                }
            })
        }).catch((error) => {
            me.setState({
                updateAliOverwriteLoading: false,
                updateAliOverwriteAlert: {
                    ...updateAliOverwriteAlert,
                    loading: false,
                    loadingText: '',
                    open: false
                },
                error: {
                    open: true,
                    message: error.message
                },
            });
        });
    }

    showUpdateAliOverwriteAlert(postData, title, text, buttons, handler) {
        const me = this;
        const { classes } = this.props;
        const { updateAliOverwriteAlert } = this.state;

        this.setState({
            updateAliOverwriteAlert: {
                ...updateAliOverwriteAlert,
                title,
                text,
                buttons,
                open: true,
                className: `${classes.fullHeightDialog}`,
                handler: (index) => {
                    if (index === 1) {
                        this.handleUpdateAliOverwrite(postData);
                    } else {
                        me.setState({ updateAliOverwriteAlert: { ...updateAliOverwriteAlert, open: false } });
                    }
                }
            },
        });
    }

    handleSearchValidateAliOverwrite(postData) {
        const me = this;
        const { searchValidateAliOverwriteAlert } = this.state;
        AdminAPI.searchValidateAliOverwrite(postData).then((response) => {
            this.setState({
                searchValidateAliOverwriteAlert: { ...searchValidateAliOverwriteAlert, open: false }
            })
            if (response.aliNotFound.length) {
                this.handleUpdateAliOverwrite(postData);
            } else {
                this.showUpdateAliOverwriteAlert(
                    postData,
                    `These Overwritten ALIs Are Found In MongoDB`,
                    this.updateAliOverwriteDialog(response),
                    ['Cancel', 'Submit'],
                );
            }
        }).catch((error) => {
            me.setState({
                error: {
                    open: true,
                    message: error.message
                },
            });
        });
    }

    showSearchValidateAliOverwriteAlert(postData, title, text, buttons, handler) {
        const me = this;
        const { classes } = this.props;
        const { searchValidateAliOverwriteAlert } = this.state;

        this.setState({
            searchValidateAliOverwriteAlert: {
                ...searchValidateAliOverwriteAlert,
                title,
                text,
                buttons,
                open: true,
                className: `${classes.fullHeightDialog}`,
                handler: (index) => {
                    if (index === 1) {
                        this.handleSearchValidateAliOverwrite(postData);
                    } else {
                        me.setState({ searchValidateAliOverwriteAlert: { ...searchValidateAliOverwriteAlert, open: false } });
                    }

                },
            },
        });
    }

    searchValidateAliOverwriteDialog(postData, responseData) {
        const { classes } = this.props
        return (
            <TableContainer className={classes.container}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {searchValidateAliOverwriteColumns.map((column, index) => (
                                <TableCell
                                    key={index}
                                    className={classes.dialogTableCell}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {responseData.map((row, index) => {
                            return (
                                <TableRow hover tabIndex={-1} key={row.code}>
                                    {searchValidateAliOverwriteColumns.map((column) => {
                                        const value = row[column.dataKey];
                                        if (Object.keys(row).length === 2) {
                                            return (
                                                <TableCell key={column.id} align={column.align}>
                                                    {postData.ali_list[index][column.dataKey]}
                                                </TableCell>
                                            );
                                        }
                                        return (
                                            <TableCell key={column.id} align={column.align}>
                                                {column.format && typeof value === 'number' ? column.format(value) : value}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }

    showNotFoundDuplicateAlert(postData, title, text, buttons, handler) {
        const me = this;
        const { classes } = this.props;
        const { notFoundDuplicateAlert } = this.state;

        this.setState({
            notFoundDuplicateAlert: {
                ...notFoundDuplicateAlert,
                title,
                text,
                buttons,
                open: true,
                className: `${classes.fullHeightDialog}`,
                handler: (index) => {
                    if (index === 1) {
                        this.handleSearchValidateAliOverwrite(postData);
                    } else {
                        me.setState({ notFoundDuplicateAlert: { ...notFoundDuplicateAlert, open: false } });
                        (handler || (() => { }))(index);
                    }
                }
            },
        });
    }

    getValidatedData(aliList, responseData) {
        const duplicateAlis = responseData.filter(data => data.duplicate === true)
        const conflictMariadb = responseData.filter(data => data.isDuplicateinFirstColumn === "Yes")
        const notFoundAlis = []
        aliList.forEach((postItem, index) => {
            const firstAliNotFound = responseData[index].aliFirstColumn === aliOverwriteSuffix.NOT_FOUND || responseData[index].aliFirstColumnInMongo === aliOverwriteSuffix.NOT_FOUND;
            const secondAliNotFound = responseData[index].aliSecondColumn === aliOverwriteSuffix.NOT_FOUND || responseData[index].aliSecondColumnInMongo === aliOverwriteSuffix.NOT_FOUND;
            let alis = {};
            if (firstAliNotFound || secondAliNotFound) {
                alis['incorrectAli'] = postItem.aliFirstColumn;
                alis['aliFirstColumn'] = responseData[index].aliFirstColumn;
                alis['aliFirstColumnInMongo'] = responseData[index].aliFirstColumnInMongo;
                alis['isValidAliFirst'] = responseData[index].isValidAliFirst;

                alis['correctAli'] = postItem.aliSecondColumn;
                alis['aliSecondColumn'] = responseData[index].aliSecondColumn;
                alis['aliSecondColumnInMongo'] = responseData[index].aliSecondColumnInMongo;
                alis['isValidAliSecond'] = responseData[index].isValidAliSecond;
            }
            if (Object.keys(alis).length) notFoundAlis.push(alis);
        });
        return {
            conflictMariadb,
            duplicateAlis,
            notFoundAlis,
        }
    }

    notFoundDuplicateDialog(aliList, responseData) {
        const { classes } = this.props
        const { conflictMariadb, duplicateAlis, notFoundAlis } = this.getValidatedData(aliList, responseData);
        return (
            <>
                {
                    !!notFoundAlis.length && (
                        <TableContainer className={classes.container}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell
                                            className={classes.dialogTableCell}
                                            colSpan={6}
                                        >
                                            ALIs Not Found
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell
                                            className={classes.dialogTableCell}
                                            colSpan={6}
                                        >
                                            The following ALIs were not found in MariaDB and/or MongoDB and will not be submitted
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            Incorrect ALI
                                        </TableCell>
                                        <TableCell>
                                            Correct ALI
                                        </TableCell>
                                        <TableCell>
                                            Incorrect Found in MariaDB
                                        </TableCell>
                                        <TableCell>
                                            Incorrect Found in MongoDB
                                        </TableCell>
                                        <TableCell>
                                            Correct Found in MariaDB
                                        </TableCell>
                                        <TableCell>
                                            Correct Found in MongoDB
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {notFoundAlis.map((row, index) => {
                                        return (
                                            <TableRow key={index}>
                                                 <TableCell key={`${index}-incorrect-ali`}>
                                                    {
                                                        row.incorrectAli
                                                    }
                                                </TableCell>
                                                <TableCell key={`${index}-correct-ali`}>
                                                    {
                                                        row.correctAli
                                                    }
                                                </TableCell>
                                                <TableCell key={`${index}-incorrect-mariadb`}>
                                                    {
                                                        row.aliFirstColumn === aliOverwriteSuffix.NOT_FOUND ? "No" : "Yes"
                                                    }
                                                </TableCell>
                                                <TableCell key={`${index}-incorrect-mongodb`}>
                                                    {
                                                        row.aliFirstColumnInMongo === aliOverwriteSuffix.NOT_FOUND ? "No" : "Yes"
                                                    }
                                                </TableCell>
                                                <TableCell key={`${index}-correct-mariadb`}>
                                                    {
                                                        row.aliSecondColumn === aliOverwriteSuffix.NOT_FOUND ? "No" : "Yes"
                                                    }
                                                </TableCell>
                                                <TableCell key={`${index}-correct-mongodb`}>
                                                    {
                                                        row.aliSecondColumnInMongo === aliOverwriteSuffix.NOT_FOUND ? "No" : "Yes"
                                                    }
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )
                }
                {
                    !!conflictMariadb.length && (
                        <TableContainer className={classes.container}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.dialogTableCell}>
                                            Conflict Caused: MariaDB
                                        </TableCell>
                                        <TableCell className={classes.dialogTableCell}>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            Alis
                                        </TableCell>
                                        <TableCell>
                                            Payload Date
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {conflictMariadb.map((row, index) => {
                                        return (
                                            <>
                                                <TableRow key={`${index}-firstRow`}>
                                                    <TableCell key={`${index}-aliFirstColumn`}>
                                                        {row.aliFirstColumn}
                                                    </TableCell>
                                                    <TableCell key={`${index}-firstPayloadDate`}>
                                                        {row.payload_date}
                                                    </TableCell>
                                                </TableRow>
                                            </>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )
                }
                {
                    !!duplicateAlis.length && (
                        <TableContainer className={classes.container}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.dialogTableCell}>
                                            Dupes Caused: MariaDB
                                        </TableCell>
                                        <TableCell className={classes.dialogTableCell}>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            Alis
                                        </TableCell>
                                        <TableCell>
                                            Payload Date
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {duplicateAlis.map((row, index) => {
                                        return (
                                            <>
                                                <TableRow key={`${index}-firstRow`}>
                                                    <TableCell key={`${index}-aliFirstColumn`}>
                                                        {row.aliFirstColumn}
                                                    </TableCell>
                                                    <TableCell key={`${index}-firstPayloadDate`}>
                                                        {row.payload_date}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow key={`${index}-secondRow`}>
                                                    <TableCell key={`${index}-aliSecondColumn`}>
                                                        {row.aliSecondColumn}
                                                    </TableCell>
                                                    <TableCell key={`${index}-secondPayloadDate`}>
                                                        {row.payload_date}
                                                    </TableCell>
                                                </TableRow>
                                            </>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>

                    )
                }
            </>
        )
    }

    handleValidateAliOverwrite = (event) => {
        event.preventDefault();
        const me = this;
        let postData = {
            ali_list: this.state.rows.filter(item => item.aliFirstColumn.value !== '' || item.aliSecondColumn.value !== '')
                .map(item => ({
                    aliFirstColumn: item.aliFirstColumn.value,
                    aliSecondColumn: item.aliSecondColumn.value
                })),
            list_id: this.state.selectedList.listID
        }
        AdminAPI.validateAliOverwrite(postData).then((response) => {
            this.setState({
                rows: this.state.rows
                    .map(item => ({
                        aliFirstColumn: {
                            ...item.aliFirstColumn,
                            helperText: ''
                        },
                        aliSecondColumn: {
                            ...item.aliSecondColumn,
                            helperText: ''
                        },
                        suffix: ''
                    })),
            })
            this.setState({
                validateAliData: response.validationArray
            })
            if (response.message) {
                const columnsToCheckGroup = [
                    'aliFirstColumn', 
                    'aliFirstColumnInMongo', 
                    'aliSecondColumn', 
                    'aliSecondColumnInMongo',
                  ];

                const duplicateAlis = response.validationArray.filter(data => data.duplicate === true)
                const conflictMariadb = response.validationArray.filter(data => data.isDuplicateinFirstColumn === "Yes")
                  
                  const isDisable = response.validationArray.some(obj =>
                    columnsToCheckGroup.some(column => obj[column] === aliOverwriteSuffix.NOT_FOUND)
                  ) || duplicateAlis.length || conflictMariadb.length;
                
                this.setState((prevState) => ({
                    rows: prevState.rows.map((row, index) => {
                        const validation = response.validationArray[index];
                        if (validation) {
                            return {
                                ...row,
                                aliFirstColumn: {
                                    ...row.aliFirstColumn,
                                    helperText: [
                                        validation.duplicate && 'Dupes Caused: MariaDB',
                                        validation.isDuplicateinFirstColumn === 'Yes' && 'Conflict Caused: MariaDB',
                                        validation.aliFirstColumn === aliOverwriteSuffix.NOT_FOUND && aliOverwriteSuffix.NOT_FOUND
                                    ].filter(Boolean).join(', '),
                                },
                                aliSecondColumn: {
                                    ...row.aliSecondColumn,
                                    helperText: [
                                        validation.duplicate && 'Dupes Caused: MariaDB',
                                        validation.isDuplicateinFirstColumn === 'Yes' && 'Conflict Caused: MariaDB',
                                        validation.aliSecondColumn === aliOverwriteSuffix.NOT_FOUND && aliOverwriteSuffix.NOT_FOUND
                                    ].filter(Boolean).join(', '),
                                },
                                suffix: validation.duplicate ? aliOverwriteSuffix.DUPLICATE : '',
                            };
                        } else {
                            return row;
                        }
                    })
                })); 
                this.showNotFoundDuplicateAlert(
                    postData,
                    '',
                    this.notFoundDuplicateDialog(postData.ali_list, response.validationArray),
                    [
                        { text: 'Cancel', disable: false },
                        { text: 'Submit', disable: isDisable },
                    ]
                );
            } else {
                this.showSearchValidateAliOverwriteAlert(
                    postData,
                    `Are you sure you want to overwrite the following ${response.validationArray.length} ALIs?`,
                    this.searchValidateAliOverwriteDialog(postData, response.validationArray),
                    ['Cancel', 'Submit'],
                );
            }
        }).catch((error) => {
            me.setState({
                error: {
                    open: true,
                    message: error.message
                },
            });
        });

    };

    isTextFieldDisabled = (rowIndex) => {
        return rowIndex > 0 && this.state.rows[rowIndex - 1]?.aliFirstColumn?.value === '' && this.state.rows[rowIndex]?.aliSecondColumn?.value === ''
    }

    render() {
        const { classes } = this.props;
        const { rows, selectedList, open, loading, lists, numRows, searchValidateAliOverwriteAlert,
            updateAliOverwriteAlert, success, error, notFoundDuplicateAlert, aliOverwriteResultAlert } = this.state;
        const isButtonDisabled = !selectedList || !Object.keys(selectedList).length || rows.every(obj => obj.aliFirstColumn.value === '' && obj.aliSecondColumn.value === '');

        return (
            <>
                <div className={classes.content}>
                    <CustomDropdown
                        apiEndpoint={(page, search) => ALIAPI.getLists(page, search)}
                        label="Select list"
                        optionLabelKey="listName"
                        valueKey="value"
                        onChange={(selectedList) => this.setState({ selectedList })}
                        dropDownClassName={classes.dropdown}
                    />
                    <TableView
                        tableHeaderCell={classes.tableHeaderCell}
                        tableRowEven={classes.tableRowEven}
                        data={rows}
                        showTableFooter={true}
                        rowHeight={53}
                        columns={
                            aliOverwriteColumns.map((c, index) => {
                                let column = null;
                                switch (c.dataKey) {
                                    case 'incorrectAli':
                                        column = {
                                            ...c,
                                            width: 200,
                                            flexGrow: 1,
                                            columnRenderer: (rowData, rowIndex) => (
                                                <TextField
                                                    id="outlined-helperText"
                                                    label={rowData?.aliFirstColumn.helperText || ''}
                                                    error={!!rowData?.aliFirstColumn.helperText}
                                                    className={`${classes.roundedTextField} ${classes.fullWidthTextField}`}
                                                    size='small'
                                                    type="text"
                                                    variant="outlined"
                                                    placeholder="Type here"
                                                    value={rowData?.aliFirstColumn.value || ''}
                                                    onChange={(e) => this.handleInputChange(index, rowIndex, 'aliFirstColumn', e.target.value)}
                                                    disabled={this.isTextFieldDisabled(rowIndex)}
                                                    // helperText={rowData?.aliFirstColumn.helperText || ''}
                                                />
                                            ),
                                            footerRenderer: () => (
                                                <div className={classes.formControl}>
                                                    <InputLabel
                                                        id="num-overwrites-label"
                                                        className={classes.overwrites}
                                                    >
                                                        Number of Overwrites
                                                    </InputLabel>
                                                    <Select
                                                        labelId="num-overwrites-label"
                                                        id="num_overwrites"
                                                        value={numRows}
                                                        onChange={this.handleNumRowsChange}
                                                    >
                                                        <MenuItem value={25}>25</MenuItem>
                                                        <MenuItem value={50}>50</MenuItem>
                                                        <MenuItem value={100}>100</MenuItem>
                                                        <MenuItem value={200}>200</MenuItem>
                                                        <MenuItem value={500}>500</MenuItem>
                                                    </Select>
                                                </div>
                                            )
                                        };
                                        break;
                                    case 'correctAli':
                                        column = {
                                            ...c,
                                            width: 200,
                                            flexGrow: 1,
                                            columnRenderer: (rowData, rowIndex) => (
                                                <TextField
                                                    id="outlined-helperText"
                                                    label={rowData?.aliSecondColumn.helperText || ''}
                                                    error={!!rowData?.aliSecondColumn.helperText}
                                                    className={`${classes.roundedTextField} ${classes.fullWidthTextField}`}
                                                    size='small'
                                                    type="text"
                                                    variant="outlined"
                                                    placeholder="Type here"
                                                    value={rowData?.aliSecondColumn.value || ''}
                                                    onChange={(e) => this.handleInputChange(index, rowIndex, 'aliSecondColumn', e.target.value)}
                                                    disabled={this.isTextFieldDisabled(rowIndex)}
                                                    // helperText={rowData?.aliSecondColumn.helperText || ''}
                                                />
                                            ),
                                            footerRenderer: () => <></>
                                        };
                                        break;
                                    case 'suffix':
                                        column = {
                                            ...c,
                                            width: 100,
                                            flexGrow: 1,
                                            columnRenderer: (rowData) => (
                                                <div className={classes.suffix}>
                                                    {rowData?.suffix || ''}
                                                </div>
                                            ),
                                            footerRenderer: () => (
                                                <form onSubmit={this.handleValidateAliOverwrite} className={classes.submit}>
                                                    <Button
                                                        type="submit"
                                                        color="primary"
                                                        variant="contained"
                                                        disabled={isButtonDisabled}
                                                    >
                                                        Submit
                                                    </Button>
                                                </form>
                                            )
                                        };
                                        break;
                                    default: column = { ...c }; break;
                                }
                                return column;
                            })
                        }
                    />
                    {searchValidateAliOverwriteAlert.open && <AlertDialog dialog={searchValidateAliOverwriteAlert} />}
                    {updateAliOverwriteAlert.open && <AlertDialog dialog={updateAliOverwriteAlert} />}
                    {notFoundDuplicateAlert.open && <AlertDialog dialog={notFoundDuplicateAlert} />}
                    {aliOverwriteResultAlert.open && <AlertDialog dialog={aliOverwriteResultAlert} />}
                    <CustomSnackbar
                        open={error.open || success.open}
                        message={error.message || success.message}
                        severity={error.open ? 'error' : success.open ? 'success' : ''}
                        duration={5000}
                        onClose={() => this.setState({
                            error: { ...error, open: false, message: '' },
                            success: { ...success, open: false, message: '' }
                        })}
                    />
                </div>
            </>



        );
    }
}

AdminAliOverwrite.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AdminAliOverwrite);
