import React, { useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { Tabs, Tab } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import { withStylesPropTypes } from '../helper/misc';
import AdminUsers from '../components/adminusers';
import AdminUserReport from '../components/adminuserreport';
import AdminMisc from '../components/adminmisc';
import AdminAliOverwrite from '../components/adminalioverwrite';

const useStyles = (() => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  title: {
    flexGrow: 1,
  },
  tabBar: {
    flexGrow: '0',
  },
  tab: {
    display: 'none',
    padding: '5px',
    flexGrow: '1',
  },
  activeTab: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  menuLink: {
    color: 'inherit',
    textDecoration: 'none',
  },
}));

const TAB_USERS = 0;
const TAB_USER_REPORT = 1;
const TAB_MISC = 2;
const TAB_ALI_OVERWRITE=3;

class Admin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTab: TAB_USERS,
    };
  }

  handleCurrentTabChange = (_event, value) => {
    this.setState({ currentTab: value });
  };

  setOpenAliOverwrite = (value) => {
    this.setState({ openAliOverwrite: value });
  };

  render() {
    const { classes } = this.props;
    const { currentTab, openAliOverwrite } = this.state;

    return (
      <div className={classes.root}>
        <AppBar position="static">
          <Toolbar>
            <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
              ADMIN TOOL
            </Typography>
            <Button href="/" className={classes.menuLink}>HOME</Button>
            <Button href="/login" className={classes.menuLink}>SIGN OUT</Button>
          </Toolbar>
        </AppBar>
        { openAliOverwrite ? <AdminAliOverwrite/> : 
        <>
        <Tabs
          variant="fullWidth"
          indicatorColor="primary"
          textColor="primary"
          value={currentTab}
          onChange={(event, value) => this.handleCurrentTabChange(event, value)}
          className={classes.tabBar}
        >
          <Tab label="Users" />
          <Tab label="User Report" />
          <Tab label="Misc" />
          <Tab label="Ali Overwrite"/>
         
          </Tabs>

        <div className={clsx(classes.tab, currentTab === TAB_USERS && classes.activeTab)}>
          <AdminUsers />
        </div>

        <div className={clsx(classes.tab, currentTab === TAB_USER_REPORT && classes.activeTab)}>
          <AdminUserReport />
        </div>

        <div className={clsx(classes.tab, currentTab === TAB_MISC && classes.activeTab)}>
          <AdminMisc />
        </div>

        <div className={clsx(classes.tab, currentTab === TAB_ALI_OVERWRITE && classes.activeTab)}>
          <AdminAliOverwrite />
        </div>
      </>}

      </div>
    );
  }
}

Admin.propTypes = {
  ...withStylesPropTypes,
};

export default withStyles(useStyles)(Admin);
